.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(45, 85, 219, 0.75);
}

.modal {
  position: fixed;
  top: 30vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  overflow: hidden;
}

.header {
  background: #1166b4;
  padding: 1rem;
}

.header h2 {
  margin: 0;
  color: white;
}

.content {
  padding: 1rem;
  text-align: center;
}

.errorMessage {
    font-weight: bold;
    color: red;
}

.label {
    font-weight: bold;
    padding-right: 10px;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.button {
  font: inherit;
  background: #7e0c9b;
  border: 1px solid #7e0c9b;
  padding: 0.5rem 1.5rem;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  border-radius: 20px;
}
