.runnerCard {
    border: 3px solid rgb(56, 55, 55);
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	width: 350px;
	height: 120px;
    margin: 5px 5px 5px 5px;
    text-align: center;
    float: left;
}

.runnerCard a {
    text-decoration: none;
    color: black;
}

.runnerCard p {
    font-weight: bold;
    font-size: 16px;
    margin: 5px 5px 5px 5px;
}

.parrot-container img {
    height: 50px;
    width: 50px;
}
/* 
@media (min-width: 800px) {
  .runnerCard {
    float: left;
  }
} */