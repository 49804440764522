.gallery h1 {
    text-align: center;
}

.gallery h2 {
    text-align: center;
}

.defaultText {
    text-align: center;
}

.loadingSpinner {
    height: 50px;
    width: 50px;
    left: 48%;
    display: inline;
}

.galleryList {
    align-content: center;
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
}

.listButtonContainer {
    margin: 0 10px 10px 0;
}

.listItem {
    list-style: none;
}

.listButton {
  font: inherit;
  background: #7e0c9b;
  border: 1px solid #7e0c9b;
  padding: 0.5rem 1.5rem;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
}
