.loadingSpinner {
    padding-top: 10px;
    height: 50px;
    width: 50px;
}

.dropdownArea {
    padding: 10px;
}

.localeSelector {
    background-color:whitesmoke;
}

.rawTextLink {
    text-decoration: underline;
    padding: 10px;
    font-size: small;
    cursor: pointer;
}

.header {
    color: white;
    background: #1166b4;
    padding: 10px;
    font-size: larger;
    border-radius: 10px 10px 0px 0px;
}

.rawDataView {
    color:darkslategrey;
    background-color: lightgray;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    border-radius: 0px;

    max-height: 150px;
    text-align: left;
    font-family: monospace;
    white-space: pre;
    overflow: scroll;
    margin: 10px;
}

.aurora-card {
    width: 30rem;
    max-width: 90%;
    margin: 3rem auto;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    text-align: center;
}