.weather-li {
    list-style: none;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
/*     max-width: fit-content; */
    width: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px;

    display: inline-block;
}

.dateHeader {
    font-weight: bolder;
}

.emojiDisplay {
    font-size: xx-large;
}

.infoText {
    font-size: small;
}

.aurora-card {
    width: 30rem;
    max-width: 90%;
    margin: 3rem auto;
    text-align: center;
}